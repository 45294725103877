export const publicRoutesAuthenticate = (to, from, next) => {
    // If the user is already logged in
    if (localStorage.getItem('jwt')) {
      // Redirect to the dashboard instead
  
      next({
        name: 'main-layout',
      })
    } else {
      // Continue to the login page
      next()
    }
  }
  
  export function removeEmptyProperties(obj) {
    for (const property in obj) {
      if (obj[property] === null || obj[property] === undefined || obj[property] === '') {
        delete obj[property]
      } else if (typeof obj[property] === 'object') {
        removeEmptyProperties(obj[property])
      }
    }
    return obj
  }
  
  export function debounce(func, delay) {
    let timerId
  
    return function (...args) {
      clearTimeout(timerId)
  
      timerId = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }
  
  export function generateUniqueCode() {
    // Generate a random number between 0 and 999999
    const randomNumber = Math.floor(Math.random() * 1000000)
  
    // Format the number as a 6-digit string, padded with leading zeros
    const uniqueCode = randomNumber.toString().padStart(6, '0')
  
    return uniqueCode
  }
  
  export function getInitialsFromFirstAndLastName(firstname = 'N/', lastname = 'A') {
    return `${firstname[0]} ${lastname[0]}`
  }
  
  export function getSavedState(key, parse = false) {
    const storedState = window.localStorage.getItem(key)
    if (storedState !== 'undefined' && parse) return JSON.parse(storedState)
    else if (storedState !== 'undefined') return storedState
    else return null
  }
  
  export function deepCopy(val) {
    return JSON.parse(JSON.stringify(val))
  }
  
  export function getEmptyKeys(obj) {
    const emptyKeys = []
  
    for (const key in obj) {
      if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
        emptyKeys.push(key)
      }
    }
  
    return emptyKeys
  }
  
  export function filterdData(query, data /* OPTIONS /DROPDOWN*/, selectedItem) {
    if (selectedItem)
      return data.filter((item) => {
        if (typeof item === 'string' && item != selectedItem) {
          return item.toLowerCase().includes(query)
        }
        if (selectedItem.id !== item.id)
          return Object.values(item).some((word) => String(word).toLowerCase().includes(query))
      })
    else
      return data.filter((item) => {
        if (typeof item === 'string') {
          return item.toLowerCase().includes(query)
        }
        return Object.values(item).some((word) => String(word).toLowerCase().includes(query))
      })
  }
  
  export function filteredDataStartsWith(query, data /* OPTIONS /DROPDOWN*/, selectedItem, label) {
    if (selectedItem)
      return data.filter((item) => {
        if (typeof item === 'string' && item != selectedItem) {
          return item.toLowerCase().startsWith(query)
        }
        if (selectedItem.id !== item.id) {
          if (!label) return
          return item[label].toLowerCase().startsWith(query)
        }
      })
    else {
      return data.filter((item) => {
        if (typeof item === 'string') {
          return item.toLowerCase().startsWith(query)
        }
        if (!label) return
        return item[label].toLowerCase().startsWith(query)
      })
    }
  }
  
  export function objectToFormData(obj) {
    const form = new FormData()
  
    for (const [key, val] of Object.entries(obj)) {
      if (typeof val !== 'object' || val === null) form.append(key, val)
      else {
        /* ONLY FOR FILE TYPE */
        if (val instanceof File || val instanceof Blob) form.append(key, val, val.name)
      }
    }
  
    return form
  }
  
  export function getSubObject(obj = {}, arr = []) {
    const newObj = {}
    arr.forEach((key) => {
      newObj[key] = obj[key]
    })
    return newObj
  }
  