import axios from 'axios';
import { NotificationManager } from 'components/common/react-notifications';
import { BASE_API_URL, API_PREFIX } from './env';
import { loginRoot } from 'constants/defaultValues';

const HTTP = axios.create({
  baseURL: BASE_API_URL + API_PREFIX,
  timeout: 1200000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
function token(options) {
  const jwt = localStorage.getItem('jwt')
    ? localStorage.getItem('jwt')
    : ''; /*  jwtToken.jwt */
  if (jwt && jwt.length > 0) {
    HTTP.defaults.headers.common.Authorization = jwt;
    const loggedInUser = JSON.parse(localStorage.getItem('current_user'));
    HTTP.defaults.headers.common['X-USERGROUP-ID'] =
      loggedInUser?.user_group_id;
  }
  if (!jwt && HTTP.defaults.headers.common.Authorization) {
    delete HTTP.defaults.headers.common.Authorization;
    delete HTTP.defaults.headers.common['X-USERGROUP-ID'];
  }
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = { 'Content-Type': 'application/json' };
  }
  const oldHeaders = HTTP.defaults.headers;
  Object.assign(HTTP.defaults, {
    headers: { ...oldHeaders, ...options.headers },
  });
  // for (const [key, val] of Object.entries(options.headers)) {
  //   HTTP.defaults.headers.common[key] = val
  // }
  // }
}

export function post(url, data, options = {}) {
  token(options);
  return HTTP.post(url, data, { timeout: options?.timeout });
}

export function patch(url, data, options = {}) {
  token(options);
  return HTTP.patch(url, data);
}

export function get(url, params = {}, options = {}) {
  token(options);
  return HTTP.get(url, params);
}

export function put(url, data, options = {}) {
  token(options);

  return HTTP.put(url, data);
}

export function del(url, params = {}, options = {}) {
  token(options);

  return HTTP.delete(url, params);
}

HTTP.interceptors.response.use(
  // eslint-disable-next-line func-names
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // eslint-disable-next-line func-names
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // eslint-disable-next-line no-undef
    // Handle error response
    if (error.response && error.response.status === 401) {
      // Redirect to login page and show toast message
      NotificationManager.warning(
        'User not logged in. Redirecting to login page.',
        'Error',
        3000,
        null,
        null,
        ''
      );
      /*  const tournamentUrl = localStorage.getItem('redirectUrl');
      if (tournamentUrl) window.location.href = '/user/register';
      else  */ window.location.href = loginRoot;
    } else if (error.response && error.response.data.message) {
      NotificationManager.warning(
        error.response.data.message,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.response && typeof error.response.data === 'string') {
      NotificationManager.error(
        error.response.data,
        'Error',
        3000,
        null,
        null,
        ''
      );
    } else if (error.response && typeof error.response.data.error) {
      NotificationManager.error(
        error.response.data.error,
        'Error',
        3000,
        null,
        null,
        ''
      );
    }
    return Promise.reject(error);
  }
);
