import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { auth } from 'helpers/Firebase';
import { post } from 'services/api.service';

import { adminRoot, currentUser, loginRoot } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import { objectToFormData } from 'utils/general-utils';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchUpdateUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_USER, loginWithEmailPassword);
}

export function* watchUpdateUserAndGroup() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_USER, updateUserAndGroup);
}

function* updateUserAndGroup({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    setCurrentUser(payload.user);
    yield put(loginUserSuccess(payload.user));
  } catch (error) {
    yield put(loginUserError(error));
  }
}

const loginWithEmailPasswordAsync = async (payload) =>
  // {
  //   headers: { 'Content-Type': 'multipart/form-data' },
  // }
  // eslint-disable-next-line no-return-await
  {
    if (window.playersubscription) {
      payload.playersubscription = JSON.stringify(window.playersubscription);
    }
    return await post('auth/login', payload).then((user) => user);
  };
function* loginWithEmailPassword({ payload }) {
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, payload.user);
    // const item = { uid: loginUser.data, ...currentUser };
    setCurrentUser(loginUser.data);
    yield put(loginUserSuccess(loginUser.data));
    /* const tournamentUrl = localStorage.getItem('redirectUrl');
    if (tournamentUrl)
      history.push(tournamentUrl.split('https://lordleague.com')[1]);
    else */ history.push(adminRoot);
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (payload) =>
  // eslint-disable-next-line no-return-await
  await post('auth/signup', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((user) => user);

function* registerWithEmailPassword({ payload }) {
  // const { email, password } = payload.user;

  const { history } = payload;
  try {
    console.log(payload);
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      objectToFormData(payload.user)
    );
    // if (!registerUser.message) {
    // const item = { uid: registerUser.user.uid, ...currentUser };
    // setCurrentUser(item);
    yield put(registerUserSuccess(registerUser));
    history.push(loginRoot);
    // } else {
    // yield put(registerUserError(registerUser.message));
    // }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

// const logoutAsync = async (history) => {
//   await auth
//     .signOut()
//     .then((user) => user)
//     .catch((error) => error);
//   history.push(adminRoot);
// };

function* logout({ payload }) {
  // const { history } = payload;
  setCurrentUser();
  // yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  // return await auth
  //   .sendPasswordResetEmail(email)
  //   .then((user) => user)
  //   .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  // return await auth
  //   .confirmPasswordReset(resetPasswordCode, newPassword)
  //   .then((user) => user)
  //   .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchUpdateUserAndGroup),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
