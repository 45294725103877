export const DOMAIN_TITLE = 'EZYAgent';
export const API_URL =
  process.env.REACT_APP_BACKEND_URL || 'stagingbe.lordleague.com';
export const PROJECT_URL = 'app.ezyagent.com';
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'staging';
// DEV_URL = ezyagent-vue-staging-6baa3b6fc6a7.herokuapp.com
export const URL_SCHEME = process.env.REACT_APP_URL_SCHEME || 'https';
export const SOCKET_SCHEME = 'ws';
export const BASE_API_URL = `${URL_SCHEME}://${API_URL}`;
export const BASE_PROJECT_URL = `${URL_SCHEME}://${PROJECT_URL}`;
export const FEATURES_ENVIRONMENT = 'prod';
export const SOCKET_URL = `${SOCKET_SCHEME}://${API_URL}/cable`;
export const STRIPE_SECRET =
  'sk_test_51N5UJUD4D0h3IFqXjQ29vG394RXp8CUCY6qINVUtd2B7AtB8ekiuq3C2ooceI9pVRb8KHYOvYnt6kqqp8LtaS53q00AQakSmDK';
export const JOIN_TOURNAMENT_LINK =
  'https://lordleague.com/app/pubg/tournaments/new?tournament_id=1';
export const STRIPE_PUBLISHABLE_KEY =
  'pk_test_51N5UJUD4D0h3IFqX9OO6czBYmeY7qIeX8kcoM9HenAMONbW2AgPv28A9pPWOBN4aNOUnANT2t7sYjPz2oKUQ50vO00W2fW5lX4';

export const API_PREFIX = '/api/';

export const PROD_FEATURES = [
  'super-dashboard',
  'tenants',
  'team-manager',
  'team-members',
];
export const DEV_FEATURES = [...PROD_FEATURES];

export const ALLOWED_FILTERS = ['tenants'];
export const ROLES_LIST = ['user', 'admin', 'referral_agent', 'staff'];
